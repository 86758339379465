.grid-explorer-container {
  
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;

  h3 {
    margin-bottom: 12px;
  }

  a {
    text-decoration: underline;
  }

  .content {
    width: 100vh;
    min-width: 1200px;
    display: flex;
  }

  .controls {
    justify-content: start;
    align-items: flex-start;
    height: 600px;
    padding-right: 20px;
  }

  label {
    display: block;
    font-size: 24px;
    margin-bottom: 20px;
  }

  input#radius {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    box-sizing: border-box;
    margin-bottom: 20px;
  }

  #paperCanvas {
    position: relative;
    width: 700px;
    height: 600px;
    background-color: #fff;
  }

  button {
    padding: 12px 24px;
    border-radius: 6px;
    border: none;
    font-family: 'Montserrat', sans-serif;
    background-color: orange;
    font-size: 16px;
    cursor: pointer;

    &:hover {
      background-color: rgb(255, 123, 0);
    }
  }

  ul li span {
    color: orange;
  }

  ul li {
    margin-bottom: 12px;
    font-size: 18px;
    line-height: 150%;
    list-style: none;
  }

  ul {
    margin: 0;
    padding: 0;
    margin-top: 24px;
  }

  .options {
    padding: 0px;
    margin: 0px;
    margin-top: 20px;
    color: #999;
    list-style: none;
    display: flex;
    gap: 40px;
  }

  .options li span {
    display: inline-block;
    padding: 20px;
    cursor: pointer;
  }

  .options li span.active, .options li span:hover {
    color: #fff;
  }
    
}