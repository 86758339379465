@import '../styles/common.scss';

.work-details-container {
  margin-top: 50px;

  @media (max-width: 500px) {
    margin-top: 0;
  }

  .image-container {
    margin: auto;
    margin-top: 80px;
    height: 600px;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    @media (max-width: 500px) {
      height: auto;
      margin-top: 40px;
    }
  
    img, video {
      height: 100%;
      display: block;

      @media (max-width: 500px) {
        width: 100%;
        padding: 25px;
        height: auto;
      }
    }
  }

  .design-desc {
    width: 780px;
    font-size: $font-small;
    line-height: 150%;
    text-align: center;
    margin: 60px auto;
    box-sizing: border-box;
    // margin-bottom: 60px;

    @media (max-width: 500px) {
      margin: 0;
      padding: 25px;
      width: 100%;
      text-align: left;
    }
  
    h2 {
      font-size: $font-large;
      line-height: 100%;
      margin-bottom: 30px;

      @media (max-width: 500px) {
        font-size: $font-med;
        line-height: 140%;
      }
    }

    p {
      margin: 0;
      font-size: $font-small;
      font-weight: 500;
      margin-bottom: 20px;

      @media (max-width: 500px) { 
        line-height: 140%;
      }
    }
  }
}

.fixed-back-btn-container  {
  position: fixed;
  padding: 20px;
  background-color: $bg-color;
  z-index: 10;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;

  button {
    background-color: none;
    border: none;
    font-size: $font-small;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
  }
}

.close-btn {
  // padding: 40px;
  z-index: 10;
  top: 60px;
  right: 60px;
  position: fixed;
  height: 60px;
  width: 60px;
  background: none;
  cursor: pointer;
  border: none;
  transition: 0.2s;
  border-radius: 50px;
  padding: 20px;
  display: block;


  &:before, &:after {
    position: absolute;
    left: 28px;
    top: 5px;
    content: ' ';
    height: 50px;
    width: 3px;
    border-radius: 3px;
    background-color: #333;
    transition: 0.3s;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }

  &:hover {
    background-color: #000;
    transform: scale(1.3);

    &:before {
      transform: scale(0.6) rotate(45deg);
      background-color: #fff;
    } 

    &:after {
      transform: scale(0.6) rotate(-45deg);
      background-color: #fff;
    }
    
  }
}

.content-item {

  .image-content {
    width: 1200px;
    margin: auto;
    margin-bottom: 60px;
    text-align: center;

    @media (max-width: 500px) {
      width: 100%;
      margin: 0;
      padding: 25px;
    }

    p {
      color: $secondary-color;
      font-weight: 500;
    }
    img {
      width: 100%;
    }

    video {
      width: 100%;
    }
  }

  .text-content {
    margin: auto;
    width: 1200px;
    text-align: left;
    display: flex;
    gap: 50px;
    margin-bottom: 60px;

    @media (max-width: 500px) { 
      width: 100%;
      display: block;
      padding: 25px;
      margin-bottom: 0;
      font-size: $font-small;
      box-sizing: border-box;
    }

    h3 {
      font-size: $font-med;
      width: 30%;
      text-align: right;

      @media (max-width: 500px) { 
        width: 100%;
        text-align: left;
        margin-bottom: 25px;
      }
    }

    .content {
      font-size: $font-small;
      line-height: 180%;
      width: 70%;
      padding: 0;
      margin: 0;

      @media (max-width: 500px)  { 
        width: 100%;
      }

      ul {
        margin: 0;

        li {
          margin-bottom: 20px;
          list-style-type:square;
        }
      }

      p {
        margin: 0;
        font-size: $font-small;
        font-weight: 500;
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }

        @media (max-width: 500px) { 
          line-height: 140%;
        }
      }
    }
  }
  
}