.playground-container {
  .art-container.dots-container {
    background-color: #000;

    .art {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    
    #paperCanvas {
      position: relative;
      // width: 100%;
      // height: 100%;

      width: 100%;
      height: 100%;
      max-width: 600px;
      max-height: 600px;
      box-sizing: border-box;
      // top: 50px;
      
      @media (max-width: 500px) {
        height: 80vh;
        padding: 20px;
        top: 0;
      }
    }

    h2 {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 2.5vw;
      color: #fff;
      z-index: 0;
      mix-blend-mode: difference;
  
      @media (max-width: 500px) {
        font-size: 48px;
        padding: 25px;
        width: 100%;
      }
    }
  }
}