@import '../styles/common.scss';

header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 80px 0px;

  @media (max-width: 500px) {
    // flex-direction: column;
    flex-direction: column-reverse;
    padding: 20px;

    #playground-scroll-link {
      display: none;
    }
  }

  a {
    color: $secondary-color;
    transition: 0.8s;
    display: inline-block;
    padding: 20px 0;

    @media (max-width: 500px) {
      padding: 5px 0;
    }

    &:after {
      display:block;
      content: '';
      padding-bottom: 5px;
      border-bottom: solid 2px $primary-color;  
      transform: scaleX(0);
      transform-origin: 100% 50%;
      transition: transform 0.5s ease-out;
    }
    
    &:hover {
      color: $primary-color;
      &:after { 
        transform: scaleX(1); 
        transform-origin: 0% 50%; 
      }
    }
  }

  h1 {
    font-size: $font-med;
    font-weight: 600;

    a {
      color: $primary-color;

      &:after {
        border-bottom: solid 5px $primary-color;
      }
    }
  }
}