@import '../common.scss';

.let-it-rain-container {
  background: #000000;  /* fallback for old browsers */
  position: relative;
  width: 100%;
  height: 100%;
  -webkit-touch-callout:none;
  -webkit-user-select:none;
  -khtml-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;

  @media (max-width: 500px) {
    display: flex;
    justify-content: center;
  }

  h1 {
    color: #111;

    @media (max-width: 500px) {
      position: absolute;
      top: 100px;
      font-size: 112px;
    }
  }

  span#letItRainBtn {
    display: inline-block;
    position: absolute;
    padding: 20px 70px;
    font-family: 'Bebas Neue', sans-serif;
    font-size: 32px;
    cursor: pointer;
    background-color: #111;
    color: #333;
    border: 1px #333 solid;
    border-radius: 10px;
    text-decoration: none;
    top: 12vw;
    left: 50%;
    transform: translateX(-50%);
    z-index: 5;
    transition: 0.3s;

    -webkit-touch-callout:none;
    -webkit-user-select:none;
    -khtml-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;

    @media (max-width: 500px) {
      left: auto;
      transform: translateX(0);
      top: 250px;
    }

    &:hover {
      background-color: #333;
      color: #111;
      transform: translateX(-50%) translateY(-3px);

      @media (max-width: 500px) {
        transform: translateX(0) translateY(-3px);
      }
    }
  
    &:active {
      background-color: #111;
      color: #999;
      transform: translateX(-50%) translateY(3px);

      @media (max-width: 500px) {
        transform: translateX(0) translateY(3px);
      }
    }
  }

  p {
    color: #333;
    bottom: 40px;
    position: absolute;
    font-size: 24px;
    width: 100%;
    text-align: center;;
    font-family: 'Arial', sans-serif;
  }
}