@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

.moutain-container {
  height: 100vh;
  overflow: hidden;
  background-color: rgb(29, 22, 38);
  transition: 0.5s;

  h1 {
    z-index: 0;
    font-family: 'Bebas Neue', sans-serif;
    font-size: 13vw;
    position: absolute;
    color: #fff;
    opacity: 0.1;
    left: 50%;
    top: 3%;
    transform: translateX(-50%);
    width: 100%;
    text-align: center;

    @media (max-width: 500px) {
      position: absolute;
      top: 100px;
      font-size: 72px;
    }
  }

  .webgl {
    /* position: fixed; */
    z-index: 0;
    width: 100%;
    height: 100%;
    /* top: 100px; */
    left: 0;
  }

  .options {
    position: absolute;
    top: 275px;
    left: 50%;
    display: flex;
    transform: translateX(-50%);
    font-size: 32px;
    z-index: 2;
    gap: 40px;

    @media (max-width: 500px) {
      top: 200px;
      font-size: 24px;
      gap: 20px;
    }
  }

  .options li {
    display: inline-block;
    font-family: 'Bebas Neue', sans-serif;
    cursor: pointer; 
  }

  .options li span.active {
    border: 1px solid #fff;
    color:#fff;
  }

  .options li span {
    padding: 20px 40px;
    border: 1px solid rgb(50, 50, 50);
    color:rgb(45, 45, 45);
    border-radius: 20px;
    background-color: rgb(29, 22, 38);
    -webkit-touch-callout:none;
    -webkit-user-select:none;
    -khtml-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
  }

  .container {
    /* padding: 40px 0; */
    position: absolute;
    left: 50%;
    top: 58%;
    transform: translate(-50%,-40%);
    width: 800px;
    height: 600px;
    border-radius: 40px;
    background-color: rgb(29, 22, 38);
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.3);
    // box-shadow: 0 -7px 52px rgb(0 0 0  / 30%);
    /* box-shadow: #000 0.3 20; */
  }
}