@import '../common.scss';

.playground-container {
  .art-container.musical-container {
    background-color: #000;

    .art {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    h1 {
      opacity: 0.1;
      z-index: 1;
      font-family: 'Bebas Neue', sans-serif;
      font-size: 10vw;
      position: absolute;
      color: #fff;
      left: 50%;
      top: 10%;
      transform: translateX(-50%);
      width: 100%;
      text-align: center;
    }

    .gui-controls {
      // position: absolute;
      // top: 0;
    // right: 20px;
      // z-index: 10;
      margin-bottom: 20px;

      @media (max-width: 500px) {
        margin-top: 20px;
        margin-bottom: 60px;
      }
    }
    
    
    #musicalInstrument {
      position: relative;
      width: 100%;
      height: 100%;

      @media (max-width: 500px) {
        top: 0;
        height: 60vh;
      }
    }

    h2 {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 2.5vw;
      color: #fff;
      z-index: 0;
      mix-blend-mode: difference;
  
      @media (max-width: 500px) {
        font-size: 48px;
        padding: 25px;
        width: 100%;
      }
    }
  }
}