@import './common.scss';

.hero {

  display: flex;
  position: relative; 

  @media (max-width: 500px) { 
    flex-direction: column-reverse;
  }

  .info {
    position: relative;
    z-index: 1;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 500px) { 
      padding: 25px;
      margin-top: 30px;
      height: auto;
    }

    h2 {
      font-size: $font-large;
      overflow: hidden;
      position: relative;

      @media (max-width: 500px) {
        font-size: $font-med;
        // text-align: center;
      }
  
      span {
        display: inline-block;
        position: relative;
  
        .white {
          color: #fff;
        }
      }
    }
  
    .line {
      position: absolute;
      top: 90px;
      left: -20px;
      stroke-dasharray: 1000;
      stroke-dashoffset: 300;

      @media (max-width: 500px) { 
        top: -10px;
        transform: scale(0.7);
      }
    }
  
    p {
      padding-top: 20px;
      font-size: $font-small;
      line-height: 180%;
      padding-bottom: 0;
      margin-bottom: 0;

      @media (max-width: 500px) { 
        // text-align: center;
      }
    }
  }

  .image-container {
    position: absolute;
    z-index: 0;
    right: 0px;
    width: 550px;
    height: 500px;
    // background-color: red;

    #imageContainer {
      width: 100%;
      height: 100%;
      position: absolute;
    }

    @media (max-width: 500px) {
      position: relative;
      background: transparent;
      // right: auto;
      width: 100%;
      // display: none;
      height: auto;
    }
    
    img {
      width: 100%;
      
      @media (max-width: 500px) {
        // display: none;
      }
    }

    #antimatter {
      width: 500px;
      height: 500px;
      z-index: 3;
      position: absolute;
      right: -200px;
      bottom: -200px;
      cursor: grab;

      @media (max-width: 500px) {
        // right: auto;
        bottom: -120px;
        right: -100%;
        position: absolute;
        // bottom: auto;
        width: 800px;
        height: auto;
      }
    }
  }

  .lime-star {
    position: absolute;
    top: 500px;
    left: -340px;
    animation: spin 10000ms infinite linear;
  }

  @keyframes spin {
    from { 
        transform: rotate(0deg); 
    } to { 
        transform: rotate(360deg); 
    }
  }
}