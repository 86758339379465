@import '../styles/common.scss';

.work-container {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  // flex-flow: column wrap;
  gap: 2%;

  h2 {
    font-size: $font-large;
  }

  ul.options {
    display: flex;
    justify-content: space-between;
    margin: 0;

    @media (max-width: 500px) {
      // flex-direction: column;
      overflow-x: auto;
      gap: 30px;
      justify-content: start;
      flex-wrap: nowrap;
      padding: 0 25px;
    }
    
    li {
      margin: 10px 0px;
      padding: 10px 0px;
      display: inline-block;

      @media (max-width: 500px) {
        display: inline-block;
        // min-width: auto;
        // text-align: center;
        flex-shrink: 0;
        margin: 0;
      }

      a {
        font-size: $font-small;
        color: $secondary-color;
        font-weight: 600;

        &.active, &:hover {
          color: $primary-color;
        }
      }
    }
  }

  .designs {
    width: 100%;
    // max-width: 1200px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(24, 1fr);
    padding: 0 calc(100vw * (1/24));
    margin-top: 5rem;

    @media (max-width: 500px) {
      grid-template-columns: repeat(1, 1fr);
      margin-bottom: 100px;
    }
  
    .design-item {

      .media-container {
        background-color: red;
        height: 0;
        padding-bottom: 100%;
      }

      a {
        display: block;
        color: $primary-color;
        transition: transform 0.2s ease-in;

        &:hover {
          transform: scale(1.03);
        }
      }

      grid-column: span 8;
      font-size: 30px;
      padding: 0 calc(100vw * (1/24));
      margin: 0 0 calc(100vw * (2/24)) 0;
      box-sizing: border-box;

      @media (max-width: 500px) {
        width: 100%;
      }

      .info {
        padding: 8px 0;
        padding-top: 12px;
        // border-bottom: 1px solid $primary-color;
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        font-weight: 600;
        span {
          display: inline-block;
        }
      }

      img {
        max-width: 100.1%;
        display: block;
        // height: 50%;
        // aspect-ratio: 3/4;
      }

      video {
        // height: 100%;
        width: 100.1%;
        display: block;
      }
    }
  }
}