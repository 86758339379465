@import './common.scss';

.playground-container {
  position: relative;
  overflow: hidden;
  width: 100vw;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-start;
  align-content: space-between;

  @media (max-width: 500px) {
    flex-direction: column;
  }

  .art-container {
    background-color: #f9f9f9;
    height: 100vh;
    display: flex;
    justify-content: space-around;
    align-items: stretch;
    
    @media (max-width: 950px) {
      flex-direction: column;
      height: auto;
      justify-content: flex-start;
    }

    .art {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      
      #paperCanvas {
        position: relative;
        width: 100%;
        height: 100%;
        max-width: 600px;
        max-height: 600px;
        
        @media (max-width: 500px) {
          top: 0;
        }
      }
    }
  }

  .piece {
    width: 100%;
  }
  
  .sidebar {
    width: 300px;
    flex: 0 0 300px;
    padding: 40px;
    background-color: #000;
    border-right: 1px solid #373733;

    @media (max-width: 1200px) {
      width: 100%;
      flex: 0 0 100%;
      display: none;

      ul {
        display: none;
      }
    }  

    ul {
      li {
        text-align: right;
        font-size: $font-small;
        margin-bottom: 15px;
        a {
          color: #373733;

          &.selected {
            pointer-events: none;
            color: #fff;
          }
        }
      }
    }

    h3 {
      color: $peach;
      text-align: right;
      font-size: $font-med;
      line-height: 30px;
      margin-bottom: 40px;

      span {
        font-size: $font-small;
      }
    }
  }

  .info-box {
    width: 350px;
    flex: 0 0 350px;
    padding: 40px;
    text-align: left;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: #000;
    overflow-y: scroll;

    h3 {
      color: #fff;
    }

    p {
      color: #999;
      margin-top: 20px;
      line-height: 150%; 
    }

    .trademark {
      ul {
        li {
          margin-bottom: 20px;
          position: relative;
          a {
            display: block;
            text-align: center;
            background-color: #111;
            color: #999;
            padding: 10px;
          }          
        }
      }
    }

    .options {
      // padding: 0px;
      margin: 40px 0px;
      color: #999;
      display: flex;
      flex-direction: column;
      gap: 20px;
  
      li {

        input {
          font-size: $font-small;
          font-weight: 600;
          font-family: 'Montserrat', Arial, sans-serif;
          padding: 10px 20px; 
          width: 100%;
          color: blue;
        }

        button {
          width: 100%;
          border: none;
          font-size: $font-small;
          font-family: 'Montserrat', Arial, sans-serif;
          display: inline-block;
          background-color: #C4C4C4;
          padding: 10px 20px;
          cursor: pointer;
          color: #000;
          transition: 0.2;
    
          &:hover {
            background: linear-gradient(23.18deg, #EC008C 2.3%, #FC6767 83.8%);
            color: #fff;
          }
        }
    
        @media (max-width: 500px) {
          padding-top: 0px;
          margin: 0px;
        }
      }
    }


    @media (max-width: 950px) {
      width: 100%;
      height: auto;
      flex: 0 0 auto;

      h3 {
        order: 2;
      }
      p {
        order: 3;
      }

      .options {
        order: 1;
        margin-top: 0;
      }

      .trademark {
        order: 4;
      }
    }
  }

  .info-bar {
    background-color: #000;
    position: absolute;
    bottom: 0;
    width: 100%;

    ul {  
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      gap: 20px;
      padding-right: 60px;

      li {
        a {
          color: #fff;
        }
      }
    }
  }

  a {
    position: relative;
    z-index: 3;
  }

  h1 {
    z-index: 1;
    font-family: 'Bebas Neue', sans-serif;
    font-size: 20vw;
    position: absolute;
    color: #fff;
    left: 50%;
    top: 0%;
    transform: translateX(-50%);
    width: 100%;
    text-align: center;
  }

  canvas {
    width: 100vw;
    height: 100vh;
    z-index: 2;
    position: relative;
    top: 0px;
    left: 0px;
  }
}