@import '../styles/common.scss';

footer.container {
  margin-top:50px;
  // padding-bottom: 100px;

  h2 {
    font-size: $font-large;
    margin-bottom: 50px;
  }

  li {
    margin-bottom: 20px;
  }
}

.footer-content {
  display: flex;

  @media (max-width: 500px) {
    flex-direction: column-reverse;
    padding: 25px;
  }

  div {
    width: 50%;

    @media (max-width: 500px) {
      width: 100%;
    }

    .connect-links {
      font-size: $font-small;

      li {
        margin-bottom: 24px;

        a {
          color: $primary-color;
          display: inline-block;
          transition: 0.3s;

          &:hover {
            color: $pink;
          }
        }
      }

      img {
        width: 24px;
        position: relative;
        top: 8px;
        margin-right: 10px;
      }
    }
    
    img {
      position: relative;
      margin-top: -100px;
      width: 100%;

      @media (max-width: 500px) {
        margin-top: -160px;
      }
    }
  }
}