.playground-container {
  .art-container.flow-field-container {
    background-color: #000;

    #paperCanvas {
      width: 100%;
      height: 100vh;

      max-width: 600px;
      max-height: 600px;

      @media (max-width: 500px) {
        width: 100%;
        height: 100%;
      }
    }

    .gui-controls {
      // position: absolute;
      // top: 0;
    // right: 20px;
      // z-index: 10;
      margin-bottom: 20px;

      @media (max-width: 500px) {
        margin-top: 20px;
        margin-bottom: 60px;
      }
    }
    
  }
}