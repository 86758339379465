@import './styles/common.scss';

// normalize

html {
  // scroll-behavior: smooth;
}

body {
  font-family: 'Montserrat', sans-serif;
  background-color: $bg-color;
  color: $primary-color;
  opacity: 0;
  overscroll-behavior-y: none;
}

ul {
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
  cursor: pointer;
}

h1, h2, h3 {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

// loading screen

.loading-screen {
  width: 100vw;
  height: 0;
  background-color: rgb(22, 22, 21);
  position: fixed;
  z-index: 10;
}

// container

.container {
  width: 1200px;
  position: relative;
  margin: auto;

  @media (max-width: 500px) {
    width: 100%;
  }
}

.App {
  overflow: hidden;
}