.playground-container {
  .art-container.rocks-container {
    background-color: #000;

    .art {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    
    #rocksWebgl {
      position: relative;
      width: 100%;
      height: 100%;
      top: 50px;

      @media (max-width: 500px) {
        top: 0;
        height: 60vh;
      }
    }

    h2 {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 2.5vw;
      color: #fff;
      z-index: 0;
      mix-blend-mode: difference;
  
      @media (max-width: 500px) {
        font-size: 48px;
        padding: 25px;
        width: 100%;
      }
    }
  }
}