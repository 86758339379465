.playground-container {
  .art-container.color-beats-container {
    background-color: none;

    #paperCanvas {
      max-width: 100%;
      width: 100%;
      height: 100vh;
      max-height: 100vh;

      @media (max-width: 500px) {
        max-height: 80vh;
      }
    }
  }
}