
.space-travel-container {

  height: 100%;
  overflow: hidden;
  background-color:#000;

  h1 {
    z-index: 0;
    font-family: 'Bebas Neue', sans-serif;
    font-size: 20vw;
    position: absolute;
    color: #fff;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
  
  p {
    color: #fff;
    font-family: sans-serif;
    font-size: 18px;
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  #spaceTravel {
    z-index: 1;
    left: 0;
  }
}