@import './common.scss';

.image-slider {
  margin-top: 60px;
  // width: 100vw;
  // overflow-y: hidden;
  // overflow-x: auto;
  position: relative;
  z-index: 5;

  @media (max-width: 500px) {
    margin-top: 30px;
  }

  .work-link {
    color: #fff;
    mix-blend-mode: difference;
    transition: 0.2s;
    font-size: 72px;
    position: absolute;
    top: 100px;
    display: inline-block;
    width: 100%;
    text-align: center;

    &:hover {
      color: #333;
    }

    @media (max-width: 500px) {
      position: relative;
      top: 0px;
      font-size: $font-med;
      font-weight: 600;
      color: $primary-color;
    }
  }

  ul {
    padding: 0;
    position: relative;
    // left: -800px;
    list-style: none;
    display: flex;
    gap: 2%;

    @media (max-width: 500px) {
      display: none;
    }
    
    li {
      list-style: none;
      // min-width: 400px;
      // height: 300px;
      width: 32%;
      // padding: 0px 20px;
      box-sizing: border-box;

      .image {
        background-color: red;
        width: 100%;
        height: 100%;
        cursor: pointer;

        transition: 0.3s;

        // &:hover {
        //   transform: scale(1.05);
        // }

        img {
          width: 100%;
          display: block;
        }
      }
    }
  }
}