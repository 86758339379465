@import './common.scss';

.blob-bottom, .blob-top {
  z-index: -1;
  position: relative;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.blob-top {
  // top: -200px;
  margin-top: -260px;
  transform: rotate(180deg);

  @media (max-width: 500px) {
    margin-top: 30px;
  }
}

.blob-bottom {
 top: -1px;
}

.playgroundLinks {
  background-color: #000;
  position: relative;
  padding-top: 200px;
  padding-bottom: 250px;

  @media (max-width: 500px) {
    padding-top: 60px;
    padding-bottom: 60px;
    top: -1px;
  }
  
  .waves {
    position: absolute;
    top: 75%;
    width: 150px;
    left: 50%;
    transform: translateX(-500px);
  }

  .gold {
    position: absolute;
    top: 30%;
    width: 150px;
    right: 50%;
    transform: translateX(500px);
  }

  .pink-star {
    position: absolute;
    bottom: -420px;
    left: 5%;
    // transform: translateX(-500px);
    animation: spin 30000ms infinite linear;
  }

  h2 {
    font-size: $font-large;
    color: $primary-color;
    width: 90%;
    text-align: center;
    margin: auto;
    padding-bottom: 100px;
    // margin-top: 240px;
    @media (max-width: 500px) {
      font-size: $font-small;
      padding-bottom: 30px;
    }
  }

  h3 {
    font-size: $font-large;
    top: 60%;
    position: absolute;
    left: 50%;
    transform: rotate(-90deg) translateY(-400px);
    color: #FBD6D7;
    background: linear-gradient(131.04deg, #FBD6D7 -4.35%, #DECFEF 91.89%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;

    @media (max-width: 500px) {
      transform: none;
      top: 0;
      left: 0;
      position: relative;
      text-align: center;
      font-size: $font-med;
      margin-bottom: 25px;
    }
  }

  ul {
    // padding: 100px 0;
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    // margin-top: 140px;
    // margin-bottom: 140px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
    li {
      // margin-bottom: 30px;
      font-size: $font-med;

      
      @media (max-width: 500px) {
        font-size: $font-small;
      }

      a {
        color: $primary-color;
        padding: 20px;
        display: inline-block;
        // transition: 0.8s;
        text-decoration: none;

        @media (max-width: 500px) {
          color: #fff;
        }
  
        &:hover {
          color: #fff;
        }
        
      }
    }
  }
}

#rocketship {
  width: 500px;
  height: 500px;
  position: absolute;
  top: 30%;
  left: 50px;
  cursor: grab;
  // background-color: red;

  @media (max-width: 500px) {
    display: none;
  }
}