.playground-container {
  .art-container.hindi-character-container {
    background-color: #000;

    #paperCanvas {
      width: 100%;
      height: 100vh;

      max-width: 600px;
      
      max-height: 600px;

      @media (max-width: 500px) {
        max-height: 80vh;
      }
    }
  }
}