@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

$font-large: 48px; 
$font-med: 32px;
$font-small: 18px;
$bg-color: #F3F3F2;
$primary-color: #373733;
$secondary-color: #AAAAA9;
$lime: #EDFE0E;
$peach: #F2D4DF;
$pink: #FC6767;

.mobile-only {
  display: none;

  @media (max-width: 500px) { 
    display: inline-block;
  }
}

.desktop-only {
  display: auto;

  @media (max-width: 500px) { 
    display: none;
  }
}