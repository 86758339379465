.playground-container {
  .art-container.letter-fall-container {
  
    .art {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    
    #paperCanvas {
      position: relative;
      width: 100%;
      height: 100%;
      max-width: 600px;
      max-height: 600px;

      @media (max-width: 500px) {
        // height: 80vh;
        top: 0;
      }
    }
  }   
}