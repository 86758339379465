.playground-container {
  .art-container.antimatter-container {
    background: #C6FFDD;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #f7797d, #FBD786, #C6FFDD);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #f7797d, #FBD786, #C6FFDD); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    .art {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    
    #antimatter {
      position: relative;
      width: 100%;
      height: 100%;

      @media (max-width: 500px) {
        height: 80vh;
      }
    }
  }
}