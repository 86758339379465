@import './common.scss';

.links {
  display: flex;
  margin-top: 140px;
  gap: 40px;

  @media (max-width: 500px) {
    flex-direction: column;
    gap: 0px;
    margin-top: 25px;
  }

  div {
    width: 100%;

    @media (max-width: 500px) {
      width: 100%;
      padding: 25px;
    }
  }

  h3 {
    font-size: $font-large;
    
    @media (max-width: 500px) {
      font-size: $font-med;
      text-align: center;
    }
    
    text-transform: lowercase;
  }

  ul {
    padding: 0;
    padding-top: 20px;

    li {
      list-style: none;
      line-height: 150%;
      font-size: $font-large;
      font-weight: 500;
      margin-bottom: 30px;

      @media (max-width: 500px) {
        font-size: $font-small;
      }

      a {
        color: $primary-color;
        background: transparent;
        display: inline-block;

        &:hover {
          color: $pink;
        }
      }
    }
  }
}
